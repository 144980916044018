import Layout from 'common/layouts/Layout';
import { InlineLink } from 'common/links/InlineLink';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import {
  H1,
  H2,
  H3,
  LegalSection,
  LegalWrapper,
  Paragraph,
  Ul,
} from 'components/pages/legal/shared';
import { SEO } from 'components/SEO';
import { formattedRetirablePhoneNumber } from 'helpers/businessConstants';
import React from 'react';

const ConsumerDebitCardholderAgreementBlueRidgeBank = () => (
  <Layout>
    <Navigation />
    <PageWrapper>
      <LegalWrapper>
        <LegalSection>
          <H1>Retirable Retirement Paycheck VISA Debit Cardholder Agreement</H1>
          <Paragraph marginBottom={20}>
            Please read this Retirable Retirement Paycheck VISA Debit Cardholder
            Agreement (the “Agreement”) carefully and retain it for your future
            reference. This Agreement applies to all transactions resulting from
            the use of your Debit Card (the “Card”) through an automated teller
            machine (ATM), a Point-of-Sale (POS) terminal or any other use of
            the Card(s), whether electronic or not, which has been made
            available to you by Retirable, Inc. (“Program Partner”), the program
            partner responsible for managing the Card program, in partnership
            with Blue Ridge Bank, N.A. (the “Bank”), member of the Federal
            Deposit Insurance Corporation (“FDIC”).
          </Paragraph>
          <Paragraph marginBottom={20}>
            Please read this Agreement carefully and keep it for your records.
            The agreement you make with us, and the rights and obligations we
            both have, are governed by and interpreted according to the
            Commonwealth of Virginia and federal law. This Agreement, together
            with other agreements or disclosures you may receive from us, such
            as the Retirable Retirement Paycheck Consumer Deposit Account
            Agreement (“Account Agreement”), establishes our and your rights and
            obligations.
          </Paragraph>
          <Paragraph marginBottom={20}>
            When you see the words “we,” “us,” or “our” in this Agreement, it
            refers to the Bank, the Program Partner acting as agent for the
            Bank, and any of the Bank&apos;s affiliates, successors, assignees,
            agents or service providers. When you see the words “you” or “your,”
            it refers to you, the owner of the Account, as well as your personal
            representatives, executors, administrators, and successors.
          </Paragraph>
          <Paragraph marginBottom={20}>
            You authorize us to pay from your deposit account (“Account”) the
            total amount of any transaction originated by proper use of the
            Card. You also agree that the use of the Card described in this
            Agreement shall be subject to the rules, regulations and fees
            governing each deposit account which is accessed by such Card,
            including but not limited to the Bank&apos;s right of set-off.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>1 Activating the Card and Setting a PIN</H1>
          <H2 medium>1.1 Card Agreement Acceptance and Card Activation</H2>
          <Paragraph marginBottom={20}>
            This Card requires activation before you may use it. You may
            activate the Card by calling 1-833-333-0417. Your activation and use
            of the Card mean that you agree to the terms and conditions
            described in this Agreement.
          </Paragraph>
          <Paragraph marginBottom={20}>
            If you do not want to activate the Card, you should destroy and
            dispose of it.
          </Paragraph>
          <H2 medium>1.2 Personal Identification Number (PIN)</H2>
          <Paragraph marginBottom={20}>
            You will be required to select a 4-digit personal identification
            number (PIN) prior to using the Card for the first time. The 4-digit
            numerical PIN issued to you is for security purposes. The PIN
            represents your signature authorizing transactions made using your
            Card(s), identifies the bearer of the Card, and serves to validate
            and authenticate the directions given to complete the Card
            transactions.
          </Paragraph>
          <Paragraph marginBottom={20}>
            YOU ARE RESPONSIBLE FOR SAFEKEEPING YOUR PIN. YOU AGREE TO TAKE ALL
            REASONABLE PRECAUTIONS SO NO ONE ELSE LEARNS YOUR PIN AND YOU AGREE
            NOT TO DISCLOSE OR OTHERWISE MAKE YOUR PIN AVAILABLE TO ANYONE NOT
            AUTHORIZED TO SIGN ON YOUR DEPOSIT AND/OR LOAN ACCOUNT(S).
          </Paragraph>
          <Paragraph marginBottom={20}>
            Your PIN should NOT be written on the Card or anywhere it is
            available to others for use by lawful and/or unlawful means.
          </Paragraph>
          <H2 medium>1.3 Permissible Uses of Your Card</H2>
          <Paragraph marginBottom={20}>
            You agree that you will use the Card only for personal, household or
            family purposes and it will not be used for any business purposes.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>2 Use of the Card</H1>
          <H2 medium>2.1 ATM Cash Deposits</H2>
          <Paragraph marginBottom={20}>
            With your PIN, you can use your Card to deposit cash into your
            Account at an in-network ATM. You can identify an in-network ATM by
            looking for the Allpoint logo on or near the ATM. You cannot make
            cash deposits at out-of-network ATMs.
          </Paragraph>
          <H2 medium>2.2 ATM and Point-of-Sale PIN Withdrawals</H2>
          <Paragraph marginBottom={20}>
            With your PIN, you can use your Card to obtain cash from your
            Account up to your Available Balance at any ATM or any Point-of-Sale
            (“POS”) device in the United States that displays the Visa, VisaATM,
            Plus, Interlink, or Accel acceptance marks. Not all merchants will
            permit you to get cash back through their POS devices.
          </Paragraph>
          <Paragraph marginBottom={20}>
            If you use an ATM, including a balance inquiry, you may be charged a
            fee by the ATM operator even if you do not complete a withdrawal.
            This ATM fee is a third-party fee assessed by the individual ATM
            operator only and is not assessed or controlled by us. This ATM fee
            amount will be charged to your Account in addition to any applicable
            fees disclosed in this Agreement or your Account Agreement.
          </Paragraph>
          <H2 medium>2.3 Retail Card Purchases</H2>
          <Paragraph marginBottom={20}>
            You may use your Card to make purchases using your PIN at any
            merchant with a POS device anywhere that displays the Visa, VisaATM,
            Plus, Interlink, or Accel acceptance marks. You may also use your
            Card to make purchases without your PIN anywhere that Visa debit
            cards are accepted.
          </Paragraph>
          <H2 medium>2.4 Split Transactions</H2>
          <Paragraph marginBottom={20}>
            You can instruct a merchant to charge your Card for part of a
            purchase and pay any remaining amount with cash or another card.
            This is called a “split transaction.” Some merchants do not permit
            split transactions. If you wish to conduct a split transaction, you
            must tell the merchant the exact amount you would like charged to
            your Card. If you fail to inform the merchant you would like to
            complete a split transaction and you do not have sufficient
            available funds in your Account to cover the entire purchase amount,
            your Card is likely to be declined.
          </Paragraph>
          <H2 medium>2.5 Card-Not-Present Transactions</H2>
          <Paragraph marginBottom={20}>
            You may make retail card purchases without presenting your Card by
            providing information from your Card, such as transactions done by
            internet or telephone. Conducting transactions in this way has the
            same legal effect as if you used the Card itself. For security
            reasons, the amount or number of card-not-present transactions you
            are permitted to make may be limited.
          </Paragraph>
          <H2 medium>2.6 Foreign Transactions</H2>
          <Paragraph marginBottom={20}>
            Charges from foreign merchants and financial institutions may be
            made in a foreign currency. We will bill you in U.S. dollars based
            on the exchange rate on the day we settle the transaction, plus any
            special currency exchange charges that may be imposed by us, the
            Visa network and/or by any third-party used to complete the
            transaction.
          </Paragraph>
          <Paragraph marginBottom={20}>
            The exchange rate between the transaction currency and the billing
            currency used for processing international transactions is a rate
            selected by Visa from the range of rates available in wholesale
            currency markets for the applicable central processing date. This
            rate may vary from the rate Visa itself receives, or the
            government-mandated rate in effect for the applicable central
            processing date. The conversion rate may be different from the rate
            in effect on the date of your transaction and the date it is posted
            to your Account.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Regardless of whether the transaction is made in foreign currency or
            in U.S. dollars, additional fees may apply to all international
            transactions using the Card. Please refer to our current fee
            schedule for more information about our fees.
          </Paragraph>
          <H2 medium>2.7 Card Authorization Holds</H2>
          <Paragraph marginBottom={20}>
            When you use your Card to purchase goods or services or to obtain
            cash from a merchant, the merchant may request preauthorization
            (“Card Authorization”) for the transaction. If the merchant makes
            such a request and there are sufficient available funds in your
            Account, we will approve the transaction and place a temporary hold
            on the funds in your Account. This hold will reduce the Available
            Balance in your Account. The amount of this temporary hold will
            usually be the amount of the Card Authorization.{' '}
          </Paragraph>
          <Paragraph marginBottom={20}>
            If you use your card at a restaurant, your Available Balance may be
            reduced by the amount of the card authorization plus up to an
            additional twenty percent (20%) of that amount, to account for
            potential tipping.
          </Paragraph>
          <Paragraph marginBottom={20}>
            The card authorization will remain on your Account until the
            merchant sends us the final amount of the transaction and requests
            payment (“settlement”). In most cases, if the merchant does not
            request settlement, or the merchant is delayed in requesting
            settlement, the card authorization will automatically be removed
            after three (3) days. However, for certain merchants such as hotels
            and rental car companies, it may take up to thirty (30) days for the
            card authorization to be removed.
          </Paragraph>
          <Paragraph marginBottom={20}>
            It is important to understand that the merchant controls the timing
            of card authorizations or settlement. A merchant may request
            settlement after the card authorization has been removed from your
            Account. This means that if you use the funds in your Account after
            the card authorization has been removed and the merchant later
            requests settlement, your Account may become overdrawn. A merchant
            may also request settlement for an amount that is different than the
            card authorization. Therefore, it is important that you keep track
            of your transactions and your balance. Once we have approved a card
            authorization, we cannot stop the transaction and you will be
            responsible for repaying any negative balance that may occur.
          </Paragraph>
          <H2 medium>2.8 Customized Debit Card Limits</H2>
          <Paragraph marginBottom={20}>
            As part of the Online Banking Services, you may be given the ability
            to establish customized debit card transaction limits that are lower
            than the limits that are otherwise applicable to POS transactions
            and ATM withdrawals for yourself or any secondary cardholders to
            help you manage and control your spending.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>3 Card Limitations</H1>
          <Paragraph marginBottom={32}>
            There may be limits on the amount and frequency of withdrawals and
            deposits you can make using your Card. Please refer to the Account
            Agreement for any limits that may apply to your Account.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>4 Returns and Refunds</H1>
          <Paragraph marginBottom={20}>
            Please be aware of the merchant&apos;s return policies before using
            your Card to make a purchase. Neither the Bank nor the Program
            Partner is responsible for the delivery, quality, safety, legality
            or any other aspect of the goods or services you purchase using your
            Card. If you have a problem with a purchase that you made with your
            Card or if you have a dispute with the merchant, you must handle it
            directly with the merchant. If you are entitled to a refund for any
            reason for goods or services obtained using your Card, you agree to
            accept credits to your Card for the refund and agree to the refund
            policy of the merchant. The amounts credited to your Card for
            refunds may not be available for up to five (5) days from the date
            the refund transaction occurs.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>5 Additional Benefits from Visa and Fraud Alerts Program</H1>
          <H2 medium>5.1 Additional Benefits from Visa </H2>
          <Paragraph marginBottom={20}>
            Your Card may also be eligible for certain benefits provided by
            Visa. Please see the{' '}
            <InlineLink
              target="_blank"
              href="https://usa.visa.com/support/consumer/debit-cards.html"
            >
              Visa Debit Card Benefits Guide
            </InlineLink>{' '}
            for a full description of all additional benefits to which you may
            be eligible, specifically{' '}
            <InlineLink
              target="_blank"
              href="https://usa.visa.com/pay-with-visa/visa-chip-technology-consumers/zero-liability-policy.html"
            >
              Visa Zero Liability Benefits
            </InlineLink>{' '}
            You are also eligible for Visa’s Fraud Alerts Program.
          </Paragraph>
          <H2 medium>5.2 Fraud Alerts Program Overview</H2>
          <Paragraph marginBottom={20}>
            The Visa Fraud Alerts Program provides you with alerts via email,
            phone call, and/or text messages when Visa’s fraud monitoring system
            detects suspicious activity involving your Card.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Messaging frequency depends on account activity. For more
            information, text HELP to 23618 or call 1-833-333-0417. To cancel
            fraud text messaging services at any time reply STOP to any alert
            from your mobile device.
          </Paragraph>
          <Paragraph marginBottom={20}>
            For Fraud alerts support, call 1-833-333-0417. By giving us your
            mobile number, you agree that Fraud Alerts text messaging is
            authorized to notify you of suspected incidents of financial or
            identity fraud. HELP instructions: Text HELP to 23618 for help.
            Standard text messaging rates will apply.
          </Paragraph>
          <H3>5.2.1 Stop Instructions</H3>
          <Paragraph marginBottom={20}>Text STOP to 23618 to cancel.</Paragraph>
          <H3>5.2.2 Release of Liability</H3>
          <Paragraph marginBottom={20}>
            Alerts sent via SMS may not be delivered to you if your phone is not
            in the range of a transmission site, or if sufficient network
            capacity is not available at a particular time. Even within
            coverage, factors beyond the control of wireless carriers may
            interfere with messages delivery for which the carrier is not
            responsible. We do not guarantee that alerts will be delivered.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>6 Fees</H1>
          <Paragraph marginBottom={20}>
            There may be fees associated with your use of the Card. Please refer
            to the Account Agreement for any fees that may apply to your use of
            the Card.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>7 Errors and Lost or Stolen Cards</H1>
          <H2 medium>7.1 Error Resolution</H2>
          <Paragraph marginBottom={20}>
            In case of errors or questions about your Card transactions
            telephone us at 1-833-333-0417 as soon as you can, if you think your
            statement or receipt is wrong or if you need more information about
            a transfer listed on the statement or receipt. We must hear from you
            no later than sixty (60) days after we sent the FIRST statement on
            which the problem or error appeared.
            <Ul>
              <li>Tell us your name and account number (if any).</li>
              <li>
                Describe the error or the transfer you are unsure about, and
                explain as clearly as you can why you believe it is an error or
                why you need more information.
              </li>
              <li>Tell us the dollar amount of the suspected error.</li>
              <li>
                If you tell us orally, we may require that you send us your
                complaint or question in writing within 10 business days.
              </li>
            </Ul>
            We will determine whether an error occurred within ten (10) business
            days after we hear from you and will correct any error promptly. If
            we need more time, however, we may take up to forty-five (45) days
            to investigate your complaint or question. If we decide to do this,
            we will credit your account within ten (10) business days for the
            amount you think is in error, so that you will have the use of the
            money during the time it takes us to complete our investigation. If
            we ask you to put your complaint or question in writing and we do
            not receive it within ten (10) business days, we may not credit your
            account.
          </Paragraph>
          <Paragraph marginBottom={20}>
            For errors involving new accounts, point-of-sale, or
            foreign-initiated transactions, we may take up to ninety (90) days
            to investigate your complaint or question. For new accounts, we may
            take up to twenty (20) business days to credit your account for the
            amount you think is in error.
          </Paragraph>
          <Paragraph marginBottom={20}>
            We will provide you the results within three (3) business days after
            completing our investigation. If we decide that there was no error,
            we will send you a written explanation. You may ask for copies of
            the documents that we used in our investigation.
          </Paragraph>
          <H2 medium>
            7.2 Reporting Lost or Stolen Cards or Replacing Your Card
          </H2>
          <Paragraph marginBottom={20}>
            Tell us AT ONCE if you believe your Card or PIN has been lost or
            stolen or if you believe that a transaction has been made without
            your permission. Reporting the unauthorized use is the best way of
            keeping your possible losses down. You could lose all the money in
            your Account. If you tell us within two (2) business days after you
            learn of the loss or theft of your Card or PIN, you can lose no more
            than $50 if someone used your Card or PIN without permission. If you
            do NOT tell us within two (2) business days after you learn of the
            loss or theft of your Card or PIN, and we can prove that we could
            have stopped someone from using your Card or PIN without your
            permission if you had told us, you could lose as much as $500.
          </Paragraph>
          <Paragraph marginBottom={20}>
            If you need to report your Card as lost or stolen, report an
            unauthorized transaction, or replace your Card for any reason,
            please contact us at 1-833-333-0417 seven days a week, 24 hours a
            day. You will be required to provide personal information so that we
            may verify your identity before we will issue you a replacement
            Card.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>8 Electronic Fund Transfers</H1>
          <Paragraph marginBottom={32}>
            Your Card allows you to withdraw funds from your Account up to the
            Available Balance through Electronic Fund Transfers (“EFTs”). EFTs
            are transactions that are processed by electronic means and include,
            among others, ATM withdrawals and point-of-sale purchases at
            merchants. This Section provides you with information and important
            disclosures and terms about the EFTs that are permitted using your
            Card. You may also receive additional EFT services related to your
            Account that are not described in this Agreement. Please refer to
            your Account Agreement for more information.
          </Paragraph>
          <H2 medium>8.1 Types of EFTs Supported by Your Card</H2>
          <Paragraph marginBottom={20}>
            Your Card allows the following types of EFTs:
            <Ul>
              <li>
                Transfers to or from your Account to a merchant or other third
                party by providing the third party with your Card or Card
                information
              </li>
              <li>ATM deposits and withdrawals using your Card</li>
            </Ul>
          </Paragraph>
          <H2 medium>8.2 Limitations on EFTs</H2>
          <Paragraph marginBottom={20}>
            There are limitations on the frequency and amount of transactions
            you can make to or from your Account using your Card. These limits
            are different for each type of transaction. For limits that apply to
            Card transactions, please refer to Section 3 of this Agreement. For
            other limitations that may apply to your Account, please refer to
            your Account Agreement. Your Card may also be subject to
            security-related limits. These limits may change from time to time.
          </Paragraph>
          <H2 medium>Your Liability for Unauthorized EFTs</H2>
          <Paragraph marginBottom={20}>
            Contact us immediately if you believe that an unauthorized EFT has
            occurred or may occur concerning your Account, or if your Card or
            PIN has been lost, stolen or compromised. Calling us at
            1-833-333-0417 is the best way of keeping your losses to a minimum.
          </Paragraph>
          <Paragraph marginBottom={20}>
            You could lose all the money in your Account if you take no action
            to notify us of the unauthorized EFT or the loss or theft of your
            Card or PIN.
          </Paragraph>
          <Paragraph marginBottom={20}>
            For unauthorized EFTs, your liability will be as follows if you
            notify us of the loss:
            <Ul>
              <li>
                If you tell us within two (2) business days after learning of
                the loss, theft or compromise of your Card or PIN, you can lose
                no more than $50.
              </li>
              <li>
                If you do NOT tell us within two (2) business days and we can
                prove that we could have prevented the loss had you contacted
                us, you could lose as much as $500.00.
              </li>
              <li>
                If your statement shows EFTs that you did not make and you do
                NOT contact us within sixty (60) days after the statement was
                made available to you, you may not get back any money lost after
                the sixty (60) days if we can prove that your contacting us
                would have prevent those losses.
              </li>
            </Ul>
            We can extend these time periods if extenuating circumstances (such
            as a long trip or hospital stay) kept you from notifying us.
          </Paragraph>
          <H2 medium>
            8.4 Our Liability for a Failure To Complete A Transaction
          </H2>
          <Paragraph marginBottom={20}>
            If we fail to complete a transaction on time or in the correct
            amount when properly instructed by you in accordance with this
            Agreement, we will be liable for damages proximately caused by the
            failure or error. However, there are some exceptions. We are not
            liable, for instance:
            <Ul>
              <li>
                If the Available Balance in your Account is not sufficient to
                complete the transaction through no fault of ours.
              </li>
              <li>If the ATM you use does not have enough cash.</li>
              <li>
                If the failure is due to an equipment or system breakdown, such
                as a problem with the Online Banking Services provided by the
                Program Partner, that you knew about before you began a
                transaction.
              </li>
              <li>
                The failure was caused by an Act of God, fire or other
                catastrophe, or any other cause beyond our control despite
                reasonable precautions we have taken.
              </li>
              <li>
                If your funds are not available due to a hold or if your funds
                are subject to legal process.
              </li>
              <li>
                If we do not complete a transaction because we or Unit has
                reason to believe the transaction is unauthorized or illegal.
              </li>
              <li>If your Account is closed or inactive.</li>
              <li>
                There may be additional exceptions stated in our or the Program
                Partner&apos;s agreements with you or permitted by law.
              </li>
            </Ul>
          </Paragraph>
          <H2 medium>8.5 Our Business Days</H2>
          <Paragraph marginBottom={20}>
            Our business days are Monday through Friday, excluding federal
            holidays.
          </Paragraph>
          <H2 medium>
            8.6 Your Right to Information and Documentation of Your Card
            Transactions
          </H2>
          <Paragraph marginBottom={20}>
            You will receive information and documentation concerning any Card
            transactions that debit or credit your Account in the following
            ways:
            <Ul>
              <li>
                Statements: We will provide you information about each Card
                transaction that debits or credits your Account on your
                statements. Please refer to your Account Agreement for
                information about statements and other ways you may be able to
                access your transaction history and other Account information.
              </li>
              <li>
                Receipts: You will have the option to receive a receipt when
                making a withdrawal from an ATM or when you make a POS purchase
                at a merchant.
              </li>
            </Ul>
          </Paragraph>
          <H2 medium>8.7 Recurring Card Payments (Preauthorized Transfers)</H2>
          <Paragraph marginBottom={20}>
            If you authorize a merchant or other third party to automatically
            initiate a payment using your Card on a recurring basis, you must do
            so in writing. If you are issued a new Card with a different
            expiration date or a different number, we may (but are not obligated
            to) provide your new Card number and expiration date to a merchant
            or other third party with whom you have set up a recurring
            pre-authorized card payment to facilitate the continuation of your
            authorized recurring transactions.
          </Paragraph>
          <Paragraph marginBottom={20}>
            If you do not wish Visa to provide your new Card number and/or
            expiration date to merchants or other third parties, please let us
            know by emailing us at{' '}
            <InlineLink href="mailto:support@retirable.com">
              support@retirable.com
            </InlineLink>
            .
          </Paragraph>
          <H3>8.7.1 Notice of Varying Amounts</H3>
          <Paragraph marginBottom={20}>
            If you have authorized a merchant to originate regular
            pre-authorized debits to your Account using your Card and if these
            payments vary in amount, the merchant you are going to pay is
            required to notify you, ten (10) days before each payment, when it
            will be made and how much it will be. You may be given the option to
            choose to get this notice only when the payment would differ by more
            than a certain amount from the previous payment or when the amount
            would fall outside certain limits that you set.
          </Paragraph>
          <H3>8.7.2 Your Right to Stop Payment</H3>
          <Paragraph marginBottom={20}>
            To stop payment on a pre-authorized transaction, email us at{' '}
            <InlineLink href="mailto:support@retirable.com">
              support@retirable.com
            </InlineLink>
            .
          </Paragraph>
          <Paragraph marginBottom={20}>
            We must receive your stop-payment request at least three (3)
            business days before the payment is scheduled to be made. If you
            call, we may also require you to put your request in writing and
            provide it to us within fourteen (14) days after you call. If we
            require written confirmation and do not receive it, we may remove
            the stop-payment order after fourteen (14) days.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Make sure to provide us with (1) your name, (2) your Account number,
            (3) the company or person taking the payments, and (4) the date and
            amount of the scheduled payment you wish to stop. If you want all
            future payments from that company or person stopped, be sure to tell
            us that as well. If you do not provide us with the correct
            information, such as the correct payee or the correct amount of the
            payment you wish to stop, we may not be able to stop the payment.
          </Paragraph>
          <Paragraph marginBottom={20}>
            You may be charged a fee for each stop payment you request under
            your Account Agreement. Please refer to your Account Agreement for
            fees applicable to your Account.
          </Paragraph>
          <H3>
            8.7.3 Our Liability if We Fail To Stop a Preauthorized Transfer
          </H3>
          <Paragraph marginBottom={32}>
            If you order us to stop one of these payments three (3) business
            days or more before the payment is scheduled and provide us with all
            information requested, and we do not do so, we will be liable for
            your losses or damages proximately caused by the failure. However,
            we will not be liable if the company or person initiating the
            payments changes the dollar amount of the payment or makes other
            changes that cause us not to recognize it as the payment you
            requested be stopped.
          </Paragraph>
          <H2 medium>8.8 In Case of Errors or Questions About Your EFTs</H2>
          <Paragraph marginBottom={20}>
            If you think your statement or receipt is wrong or if you need more
            information about a transfer listed on a statement or receipt, as
            soon as you can contact 1-833-333-0417 for card related
            transactions.{' '}
          </Paragraph>
          <Paragraph marginBottom={20}>
            For all other transactions contact us at{' '}
            {formattedRetirablePhoneNumber} or{' '}
            <InlineLink href="mailto:support@retirable.com">
              support@retirable.com
            </InlineLink>
            . We must hear from you no later than sixty (60) days after we sent
            the FIRST statement on which the problem or error appeared. You must
            provide us with the following information:
            <Ul>
              <li>Your name and Account number.</li>
              <li>
                A description of the error or the EFT you are unsure about and
                explain as clearly as you can why you believe it is an error or
                why you need more information.
              </li>
              <li>The dollar amount of the suspected error.</li>
              <li>
                If you tell us orally, we may require that you send us your
                complaint or question in writing by email within ten (10)
                business days.
              </li>
            </Ul>
            We will determine whether an error occurred within ten (10) business
            days after we hear from you and will tell you the results and
            correct any error promptly. If we need more time, we may take up to
            forty-five (45) days—or ninety (90) days for Accounts open less than
            thirty (30) days or foreign-initiated transactions — to investigate
            your complaint or question. If we decide to do this, we will credit
            your Account within ten (10) business days for the amount you think
            is in error, so that you will have the use of the money during the
            time it takes us to complete our investigation. If we ask you to put
            your complaint or question in writing and we do not receive it
            within ten (10) business days, we may not credit your account. For
            Accounts open less than thirty (30) days, we may take up to twenty
            (20) business days to credit your Account for the amount you think
            is in error.
          </Paragraph>
          <Paragraph marginBottom={20}>
            We will provide you the results within three (3) business days after
            completing our investigation. If we decide that there was no error,
            we will send you a written explanation by email. You may ask for
            copies of the documents that we used in our investigation.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>9 Change of Terms</H1>
          <Paragraph marginBottom={20}>
            We can change the term of this Agreement at any time. We will
            provide advance notice to you of a change in terms where required by
            law.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>10 Account Information Disclosed to Third Parties</H1>
          <Paragraph marginBottom={20}>
            We will disclose information to third parties about your account or
            the transfers you make:
            <Ul>
              <li>Where it is necessary for completing the transaction </li>
              <li>
                To verify the existence and condition of your account with us
                (such as to confirm that sufficient funds are available to cover
                specific transactions) upon the request from a third party (such
                as a credit bureau or merchant)
              </li>
              <li>To comply with government agency or court orders </li>
              <li>If you give us your written permission</li>
            </Ul>
            You can more fully understand how we use your information by
            referring to the Bank&apos;s Privacy Policy at{' '}
            <InlineLink
              href="https://www.mybrb.com/privacy-policy.html"
              target="_blank"
            >
              https://www.mybrb.com/privacy-policy.html
            </InlineLink>{' '}
            and the Third Party Program Service Provider&apos;s Policy at
            <InlineLink
              href="https://www.unit.co/clients-privacy-policy"
              target="_blank"
            >
              https://www.unit.co/clients-privacy-policy
            </InlineLink>
            .
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H1>11 Cancellation</H1>
          <Paragraph marginBottom={20}>
            We may refuse to issue, deactivate, revoke, suspend or cancel your
            Card at any time with or without notice to you, other than as
            required by law. This includes deactivating or canceling your Card
            if it has been lost or stolen to prevent unauthorized transactions.
            You agree not to use your Card once it has been deactivated,
            revoked, suspended or canceled. We may also limit your use of the
            Card, including limiting or prohibiting specific types of
            transactions. If you would like to cancel your Card, you may do so
            at any time by contacting us through the Online Banking Services or
            by emailing at{' '}
            <InlineLink href="mailto:support@retirable.com">
              support@retirable.com
            </InlineLink>
            .
          </Paragraph>
          <Paragraph marginBottom={20}>
            The cancellation of your Card privileges will not otherwise affect
            your rights and obligations related to your Account.
          </Paragraph>
        </LegalSection>
      </LegalWrapper>
    </PageWrapper>
  </Layout>
);

export default ConsumerDebitCardholderAgreementBlueRidgeBank;

export const Head = () => (
  <SEO
    title="Consumer Debit Cardholder Agreement — Retirable"
    description="View the consumer debit cardholder agreement for Retirable."
  />
);
